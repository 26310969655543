import React from "react";
import './Footer.css';
import { BrowserRouter as Router,  NavLink } from "react-router-dom";

export default function Footer(){
    return(
        <div className="footer">
            <div className="area1">
                <NavLink to="/">
                    <img className="footer_logo" src="/images/home_images/Logo_Transparent.png" alt=""/>
                </NavLink>
                <h3>Hari Vishnu</h3>
                <a href="#Top">
                    <img className="back2top" src="/images/home_images/back-to-top-arrow.png" alt="" />
                </a>
            </div>
            <hr />
            <div className="area2">
                <div className="version">
                    <p>v 7.2.3</p>
                </div>
                <div className="copy_rights">&copy; 2023 Hari Vishnu</div>
            </div>
        </div>
    )
}